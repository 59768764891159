<template>
    <v-container>
        <v-row class="justify-center">
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title>אימות דוא"ל</v-card-title>
                    <v-card-text>
                        <p>אנא אשר את כתובת הדוא"ל שלך כדי להמשיך להשתמש באתר. לחץ על הכפתור למטה כדי לשלוח את הודעת האימות.
                        </p>
                        <v-btn @click="sendVerificationEmail" :loading="isLoading">שלח הודעת אימות</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            isLoading: false
        };
    },
    methods: {
        ...mapActions(['sendEmailVerification']),

        async sendVerificationEmail() {
            try {
                this.isLoading = true;
                await this.sendEmailVerification();
                this.$toast('Verification email sent. Please check your inbox.', 'success');
            } catch (error) {
                this.$toast(error.message, 'error');
            } finally {
                this.isLoading = false;
            }
        }
    }
}
</script>
  